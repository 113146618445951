(function (w, d) {
	'use strict';

	function moveVIC() {
		var windowWidth = w.innerWidth;
		var voiceIntuitive = document.getElementById('vic');
		var voiceIntuitiveContainerMobile = document.getElementById('vic-mobile');
		var voiceIntuitiveContainerDesktop = document.getElementById('vic-desktop');

		if(windowWidth > 768){
			voiceIntuitiveContainerDesktop.appendChild(voiceIntuitive);
		} else {
			voiceIntuitiveContainerMobile.appendChild(voiceIntuitive);
		}
	}

	w.addEventListener('resize', moveVIC);
	moveVIC();
})(window, document);
